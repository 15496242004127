.App-header {
  background-color: #000;

  .row {
    max-width: 1140px;
    margin: 0 auto;

    .logo {
      margin: 10px;
    }
  }
  
}

.network{
  padding-top: 29px !important;
  align-items: center;
  text-align: center;
}