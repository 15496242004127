@import "~bootstrap/scss/bootstrap";

body {
  background: #000;
  color: #fff;

  * {
    margin: 0;
    font-family: 'Lato', sans-serif,Arial,Helvetica;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}